// import { useContext } from "react";

import HeroImg from "../../assets/mainItems/heroImg.png";
import CateringForm from "../../components/CateringComponents/CateringForm";
import "./Catering.css";

// import WebplaceContext from "../../store/webplace-context";

const Catering = () => {


  return (
    <div className="catering-main-wrapper">
      <img src={HeroImg} alt="main show food" className="image-style" />
      <div className="catering-title">Contact us about your catering inquiry</div>
      <div className="catering-content-wrapper">
        <CateringForm />
      </div>
    </div>
  );
};

export default Catering;
