import { useContext } from "react";
import ButtonFormat from "../../ButtonFormat/ButtonFormat";

import "./MsgCard.css";
import WebplaceContext from "../../../../store/webplace-context";

const MsgCard = () => {
  const placeCtx =
    useContext(WebplaceContext).homeShowcaseItems.showcase_items_message;
  const styleCtx =
    useContext(WebplaceContext).homeShowcaseItems.showcase_styles;

  return (
    <div
      className="message-card-wrapper"
      style={{ backgroundColor: styleCtx.showcase_message_background }}
    >
      <div className="msg-info-wrapper">
        <div
          className="msg-title"
          style={{ color: styleCtx.showcase_message_font_color }}
        >
          {placeCtx.title}
        </div>
        <div
          className="msg-msg"
          style={{ color: styleCtx.showcase_message_font_color }}
        >
          {placeCtx.message}
        </div>
      </div>
      <div className="msg-btn">
        <ButtonFormat
          btnSize={styleCtx.showcase_message_button.btn_size}
          showArrow={styleCtx.showcase_message_button.show_arrow}
          arrowAtStart={styleCtx.showcase_message_button.arrow_at_start}
          arrowColor={styleCtx.showcase_message_button.arrow_color}
          showBorder={styleCtx.showcase_message_button.show_border}
          borderColor={styleCtx.showcase_message_button.border_color}
          backgroundColor={styleCtx.showcase_message_button.background_color}
          fontColor={styleCtx.showcase_message_button.font_color}
          capitalizeText={styleCtx.showcase_message_button.capitalize_text}
          btnText={placeCtx.button_text}
          url={placeCtx.message_url}
        />
      </div>
    </div>
  );
};

export default MsgCard;
