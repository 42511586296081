import { useContext } from "react";
import WebplaceContext from "../../store/webplace-context";

import MainLocations from "../../components/LocationComponents/MainLocations";

import HeroImg from "../../assets/mainItems/heroImg.png";
import "./Locations.css";

const Locations = () => {
  const locationsCtx = useContext(WebplaceContext).locationInformation.locations;

  return (
    <div className="locations-main-info-wrapper">
      <img src={HeroImg} alt="main show food" className="image-style" />
      {locationsCtx.map((location, index) => {
        return (
          <div key={location.id}>
            <MainLocations location={location} index={index} />
          </div>
        );
      })}
    </div>
  );
};

export default Locations;
